import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const statusStyles = {
  pending: { backgroundColor: "#34495e", color: "white" },
  povendor: { backgroundColor: "#9b59b6", color: "white" },
  siapdikirim: { backgroundColor: "#54a0ff", color: "white" },
  terkirim: { backgroundColor: "#30cb83", color: "white" },
  dibatalkan: { backgroundColor: "#b33771", color: "white" },
  return: { backgroundColor: "#e74c3c", color: "white" },
  default: { backgroundColor: "#d3d3d3", color: "black" },
};

const OrderDetails = ({ details, transactionId }) => {
  const navigate = useNavigate();

  console.log(`OrderDetails - transactionId: ${transactionId}`); // Log transaction_id

  const handleRowClick = () => {
    navigate(`/orders/order-details/${transactionId}`);
  };

  return (
    <Table size="small">
      <TableBody>
        {details.map((item, index) => (
          <TableRow
            key={index}
            hover
            style={{ cursor: "pointer" }}
            onClick={handleRowClick}
          >
            {" "}
            <TableCell>
              <img
                src={item.foto_produk}
                alt={item.product_name}
                style={{ width: "50px", height: "50px", borderRadius: "5px" }}
              />
              {/* Replace with actual image source */}
            </TableCell>
            <TableCell>
              <Typography variant="body1">{item.product_name}</Typography>
              <Typography variant="body2" color="textSecondary">
                SKU: {item.sku}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                ID Permintaan: {item.id}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                Color: {item.warna}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Finishing: {item.finishing}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                Custom: {item.iscustom ? "Yes" : "No"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">x{item.jumlah}</Typography>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  ...(statusStyles[
                    item.status.toLowerCase().replace(" ", "")
                  ] || statusStyles.default),
                  padding: "4px 8px",
                  borderRadius: "4px",
                  textAlign: "center",
                }}
              >
                {item.status || "Unknown"}
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderDetails;
