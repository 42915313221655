// path: src/features/products/productSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Buat instance Axios dengan interceptor untuk menambahkan token
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Gunakan base URL dari environment variable
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token"); // Ambil token dari localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Thunks untuk mengambil metadata, existing SKUs, dan menambahkan produk
export const fetchMetadata = createAsyncThunk(
  "products/fetchMetadata",
  async () => {
    const response = await axiosInstance.get("/api/products/metadata");
    return response.data;
  }
);

export const fetchExistingSKUs = createAsyncThunk(
  "products/fetchExistingSKUs",
  async () => {
    const response = await axiosInstance.get("/api/products/skus");
    return response.data;
  }
);

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (formData) => {
    const response = await axiosInstance.post("/api/products/add", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
);

export const fetchProductTypes = createAsyncThunk(
  "products/fetchProductTypes",
  async () => {
    const response = await axiosInstance.get("/api/products/product-types");
    return response.data;
  }
);

export const fetchKinamiData = createAsyncThunk(
  "products/fetchKinamiData",
  async () => {
    const responseKain = await axiosInstance.get("/api/products/kain");
    const responseStyle = await axiosInstance.get("/api/products/style");
    const responseKaki = await axiosInstance.get("/api/products/kaki");
    const responseDudukan = await axiosInstance.get("/api/products/dudukan");

    return {
      kain: responseKain.data,
      style: responseStyle.data,
      kaki: responseKaki.data,
      dudukan: responseDudukan.data,
    };
  }
);

export const fetchProductDetails = createAsyncThunk(
  "products/fetchProductDetails",
  async (productId) => {
    const response = await axiosInstance.get(`/api/products/${productId}`);
    return response.data;
  }
);

export const fetchSubcategories = createAsyncThunk(
  "products/fetchSubcategories",
  async (categoryId) => {
    const response = await axiosInstance.get(
      `/api/products/subcategories/${categoryId}`
    );
    return response.data;
  }
);

export const fetchVendorDetails = createAsyncThunk(
  "products/fetchVendorDetails",
  async (vendorId) => {
    const response = await axiosInstance.get(
      `/api/vendors/details/${vendorId}`
    );
    return response.data;
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, formData }) => {
    const response = await axiosInstance.put(`/api/products/${id}`, formData);
    return response.data;
  }
);

// Tambahkan thunk baru untuk fetchCategories
export const fetchCategories = createAsyncThunk(
  "products/fetchCategories",
  async (_, { getState }) => {
    const { products } = getState().products; // Mengambil state produk saat ini
    const uniqueCategories = new Set();
    products.forEach((product) => {
      if (product.kategori && !uniqueCategories.has(product.kategori)) {
        uniqueCategories.add(product.kategori);
      }
    });
    return Array.from(uniqueCategories);
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    metadata: {
      categories: [],
      vendors: [],
      subcategories: [],
      productTypes: [],
      skus: [],
      kain: [],
      style: [],
      kaki: [],
      dudukan: [],
      loading: false,
      error: null,
    },
    productDetails: null,
    vendorDetails: null,
    loading: false,
    error: null,
  },
  reducers: {
    fetchProductsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProductsSuccess(state, action) {
      state.loading = false;
      state.products = action.payload;
    },
    fetchProductsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setSubcategories(state, action) {
      state.metadata.subcategories = action.payload;
    },
    openModal(state, action) {
      state.productDetails = action.payload;
    },
    closeModal(state) {
      state.productDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetadata.pending, (state) => {
        state.metadata.loading = true;
      })
      .addCase(fetchMetadata.fulfilled, (state, action) => {
        state.metadata.loading = false;
        state.metadata.categories = action.payload.categories;
        state.metadata.vendors = action.payload.vendors;
        state.metadata.subcategories = action.payload.subcategories;
      })
      .addCase(fetchMetadata.rejected, (state, action) => {
        state.metadata.loading = false;
        state.metadata.error = action.error.message;
      })
      .addCase(fetchExistingSKUs.fulfilled, (state, action) => {
        state.metadata.skus = action.payload;
      })
      .addCase(fetchProductTypes.fulfilled, (state, action) => {
        state.metadata.productTypes = action.payload;
      })
      .addCase(fetchKinamiData.fulfilled, (state, action) => {
        state.metadata.kain = action.payload.kain;
        state.metadata.style = action.payload.style;
        state.metadata.kaki = action.payload.kaki;
        state.metadata.dudukan = action.payload.dudukan;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.products.push(action.payload);
      })
      .addCase(fetchProductDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.productDetails = action.payload;
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSubcategories.fulfilled, (state, action) => {
        state.metadata.subcategories = action.payload;
      })
      .addCase(fetchVendorDetails.fulfilled, (state, action) => {
        state.vendorDetails = action.payload;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        const index = state.products.findIndex(
          (product) => product.id === action.payload.id
        );
        if (index !== -1) {
          state.products[index] = action.payload;
        }
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = ["All", ...action.payload];
      });
  },
});

export const {
  fetchProductsStart,
  fetchProductsSuccess,
  fetchProductsFailure,
  setSubcategories,
  openModal,
  closeModal,
} = productSlice.actions;

export const fetchProducts = () => async (dispatch) => {
  dispatch(fetchProductsStart());
  try {
    const response = await axiosInstance.get("/api/products");
    dispatch(fetchProductsSuccess(response.data));
    dispatch(fetchCategories()); // Panggil fetchCategories setelah produk berhasil diambil
  } catch (error) {
    dispatch(fetchProductsFailure(error.message));
  }
};

export default productSlice.reducer;
