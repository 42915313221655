import React from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { logoutUser } from "../../actions/userActions"; // Import aksi logout

const LogoutButton = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser()); // Panggil aksi logout
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        borderRadius: "50%", // Sets border radius to make the background circular
        border: "4px solid white", // Border color and width
        backgroundColor: "rgba(0,109,90,0.3)", // Background color of the circle
        padding: "10px", // Padding to create space around the icon
        display: "flex", // Use flexbox
        flexDirection: "column", // Stack the icon on top of the text
        justifyContent: "center", // Center the icon horizontally
        alignItems: "center", // Center the icon vertically
        width: "80px", // Adjust the background size to match the icon size
        height: "80px", // Adjust the background size to match the icon size
        mb: 2,
      }}
      onClick={handleLogout}
    >
      <FontAwesomeIcon
        icon={faRightFromBracket}
        style={{ fontSize: "30", color: "white" }} // Styling directly
      />
      <Typography variant="srOnly" sx={{ color: "white" }}>
        Out
      </Typography>
    </Box>
  );
};

export default LogoutButton;
