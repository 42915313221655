// components/MenuItem.js
import React from "react";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const MenuItem = ({ icon, label, to }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <Box
      sx={{
        m: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer", // Menambahkan cursor pointer untuk interaktivitas
      }}
      onClick={handleClick} // Menangani klik
    >
      <Box
        sx={{
          borderRadius: "50%",
          border: "4px solid white",
          backgroundColor: "rgba(0,109,90,0.3)",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
          height: "100px",
          mb: 2,
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: "40", color: "white" }}
        />
      </Box>
      <Typography
        variant="body1"
        align="center"
        gutterBottom
        sx={{ color: "white", width: "150px" }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default MenuItem;
