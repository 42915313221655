// path: src/features/products/components/ImageUploadCard.js

import React, { useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";

const ImageUploadCard = ({ product, setProduct }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png", // Menambahkan validasi MIME type
    onDrop: useCallback(
      (acceptedFiles) => {
        setProduct((prevProduct) => ({
          ...prevProduct,
          files: acceptedFiles.map((file) =>
            Object.assign(file, { preview: URL.createObjectURL(file) })
          ),
        }));
      },
      [setProduct]
    ),
    onDropRejected: () => {
      alert(
        "File yang Anda unggah tidak didukung. Silakan unggah file JPEG atau PNG."
      );
    },
  });

  const handleRemoveImage = () => {
    if (product.files.length > 0) {
      URL.revokeObjectURL(product.files[0].preview);
    }
    setProduct((prevProduct) => ({ ...prevProduct, files: [] }));
  };

  return (
    <Box
      {...getRootProps()}
      sx={{ border: "1px dashed #ccc", p: 2, mb: 2, textAlign: "center" }}
    >
      <input {...getInputProps()} />
      {product.files.length > 0 ? (
        <Box sx={{ position: "relative" }}>
          <img
            src={product.files[0].preview}
            alt="Preview"
            style={{ width: "100%", height: "auto" }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ position: "absolute", top: 16, left: 16, opacity: 0.7 }}
          >
            Ganti
            <input {...getInputProps()} />
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleRemoveImage}
            sx={{ position: "absolute", top: 16, right: 16, opacity: 0.7 }}
          >
            Hapus
          </Button>
        </Box>
      ) : (
        <>
          <CloudUploadIcon sx={{ fontSize: 50 }} />
          <Typography>Tambahkan Foto Produk di sini</Typography>
        </>
      )}
    </Box>
  );
};

export default ImageUploadCard;
