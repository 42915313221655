// src/features/Auth/PinPage.js
import React, { useState } from "react";
import { Typography, Button, Box, Icon, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import BackspaceIcon from "@mui/icons-material/Backspace";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useNavigate, useLocation } from "react-router-dom";
import LogoutButton from "../../components/common/LogoutButton";
import { verifyPin } from "../../actions/userActions"; // Import action untuk verifikasi PIN
import theme from "../../theme";

const PINPage = () => {
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userName = userData ? userData.username : "Kamu..";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleButtonClick = (value) => {
    if (pin.length < 4) {
      setPin(pin + value);
    }
  };

  const handleDelete = () => {
    setPin(pin.slice(0, -1));
  };

  const handleClear = () => {
    setPin("");
  };

  const handleUnlock = async () => {
    const result = await dispatch(verifyPin(pin));
    if (result === "success") {
      setError("");

      // Ambil nilai redirect dari query parameter
      const params = new URLSearchParams(location.search);
      const redirectPath = params.get("redirect") || "/sales";

      navigate(redirectPath); // Navigasi ke halaman tujuan jika PIN benar
    } else {
      setError("PIN yang Anda masukkan salah.");
      setPin("");
    }
  };

  // Definisikan tombol-tombol keypad dalam beberapa array terpisah
  const keypadRows = [
    [
      { id: 1, label: "1" },
      { id: 2, label: "2" },
      { id: 3, label: "3" },
    ],
    [
      { id: 4, label: "4" },
      { id: 5, label: "5" },
      { id: 6, label: "6" },
    ],
    [
      { id: 7, label: "7" },
      { id: 8, label: "8" },
      { id: 9, label: "9" },
    ],
    [
      { id: "clear", label: "C", action: handleClear },
      { id: 0, label: "0" },
      { id: "enter", icon: <KeyboardReturnIcon />, action: handleUnlock },
    ],
  ];

  return (
    <Backdrop
      open={true}
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundImage: "url(/assets/images/background-images.webp)",
        backgroundColor: "black",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "auto",
      }}
    >
      <LogoutButton />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            marginBottom: 4,
            justifyContent: "center", // Tengahkan horizontal
            alignItems: "center", // Tengahkan vertikal
            display: "flex", // Gunakan flexbox
            flexDirection: "column", // Atur agar elemen ditata secara vertikal
          }}
        >
          <img
            src="/assets/icons/logo-ajeg-hijau.png"
            alt="Ajeg Furniture"
            style={{ width: "80px", marginBottom: "16px" }}
          />
          <Typography
            gutterBottom
            sx={{
              fontSize: isMobile ? "14px" : "20px",
              fontWeight: "semibold",
            }}
          >
            Hi {userName}, Masukkan PIN kamu{" "}
          </Typography>
          <Typography variant="h2" align="center" sx={{ height: "50px" }}>
            {pin.split("").map((digit, index) => (
              <span key={index}>•</span>
            ))}
          </Typography>
        </Box>
        <Box>
          {keypadRows.map((row, rowIndex) => (
            <Box
              key={rowIndex}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {row.map((button) => (
                <Button
                  key={button.id}
                  variant="contained"
                  sx={{
                    margin: 1,
                    width: 75,
                    height: 75,
                    borderRadius: "50%",
                    fontSize: "1.2rem",
                    backgroundColor: "rgba(0,0,0,0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.7)",
                    },
                  }}
                  onClick={() =>
                    button.action
                      ? button.action()
                      : handleButtonClick(button.label)
                  }
                >
                  {button.icon ? button.icon : button.label}
                </Button>
              ))}
            </Box>
          ))}
        </Box>
        <Button
          variant="contained"
          fullWidth
          color="success"
          sx={{
            marginTop: 3,
            backgroundColor: "#006D5A", // Ganti dengan warna latar belakang yang diinginkan
            color: "#FFFFFF", // Ganti dengan warna teks yang kontras dengan latar belakang
            ":hover": {
              backgroundColor: "#005a4f", // Ganti dengan warna latar belakang yang diinginkan saat tombol dihover
            },
          }}
          onClick={handleUnlock}
        >
          Masuk
        </Button>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};
export default PINPage;
