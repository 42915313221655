//path: src/features/Sales/slices/orderSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    updateItem: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id_produk === action.payload.id_produk
      );
      if (index !== -1) {
        state.items[index] = action.payload;
      }
    },
    removeItem: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.items.length) {
        state.items.splice(index, 1);
      }
    },
    clearOrder: (state) => {
      state.items = [];
    },
  },
});

export const { addItem, updateItem, removeItem, clearOrder } =
  orderSlice.actions;

export default orderSlice.reducer;
