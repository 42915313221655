// path: src/features/orders/OrderPrint.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderDetails } from "./ordersSlice";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PrintA5 from "./components/PrintA5";
import PrintThermal from "./components/PrintThermal";
import theme from "../../theme";
import { Print, Receipt, ReceiptLong, WhatsApp } from "@mui/icons-material";
import html2canvas from "html2canvas";
import { printImageViaBluetooth } from "../../utils/bluetoothPrinting";

const OrderPrint = () => {
  const location = useLocation();
  const transactionId = location.state?.transactionId;
  const [userName, setUserName] = useState(location.state?.userName || "");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const orderDetails = useSelector((state) => state.orders.orderDetails);
  const status = useSelector((state) => state.orders.status);
  const error = useSelector((state) => state.orders.error);

  useEffect(() => {
    if (transactionId) {
      dispatch(fetchOrderDetails(transactionId));
    }
  }, [dispatch, transactionId]);

  const [printType, setPrintType] = useState(isMobile ? "thermal" : "A5");

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleHome = () => {
    navigate("/orders");
  };

  async function printViaBluetooth(content) {
    try {
      // Minta izin dan pilih printer
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: ["000018f0-0000-1000-8000-00805f9b34fb"] }], // Ganti dengan UUID servis printer Anda
      });

      // Hubungkan ke printer
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService(
        "000018f0-0000-1000-8000-00805f9b34fb"
      );
      const characteristic = await service.getCharacteristic(
        "00002af1-0000-1000-8000-00805f9b34fb"
      );

      // Siapkan data ESC/POS
      const encoder = new TextEncoder();
      const data = encoder.encode(
        "\x1B@" + // Initialize printer
          "\x1B!0" + // Select print mode(s)
          content + // Print content
          "\n\n\n\n\n" + // Feed lines
          "\x1DV\x41\x03" // Cut paper
      );

      // Kirim data ke printer
      await characteristic.writeValue(data);

      console.log("Printing complete!");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handlePrint = async () => {
    if (printType === "A5") {
      window.print();
    } else {
      const element = document.getElementById("printArea");
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL("image/png");

      // Periksa apakah antarmuka Android tersedia
      if (window.Android && window.Android.print) {
        // Panggil fungsi print di antarmuka Android
        window.Android.print(image);
      } else {
        // Cetak melalui jendela baru di browser
        const newWindow = window.open("", "_blank");
        newWindow.document.write(
          `<img src="${image}" onload="window.print();window.close()" />`
        );
        newWindow.document.close();
      }
    }
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleHome}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Struk Pesanan
          </Typography>
          <Button variant="contained" onClick={handlePrint} sx={{ mr: 2 }}>
            <Print />
            Cetak
          </Button>
        </Toolbar>
      </AppBar>
      <Box>
        <Box
          sx={{
            mt: 12,
            ml: 2,
          }}
        >
          <Grid container>
            <Grid
              item
              onClick={() => setPrintType("thermal")}
              sx={{
                border: " solid 1px",
                borderRadius: "50px",
                px: 1,
                mr: 2,
              }}
            >
              <IconButton>
                <ReceiptLong />
                <Typography>Thermal</Typography>
              </IconButton>
            </Grid>
            <Grid
              item
              onClick={() => setPrintType("A5")}
              sx={{
                border: " solid 1px",
                borderRadius: "50px",
                px: 1,
              }}
            >
              <IconButton>
                <Receipt />
                <Typography>A5</Typography>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ my: 2 }}
        >
          {orderDetails && Object.keys(orderDetails).length > 0 ? (
            printType === "thermal" ? (
              <PrintThermal orderDetails={orderDetails} userName={userName} />
            ) : (
              <PrintA5 orderDetails={orderDetails} userName={userName} />
            )
          ) : (
            <Typography>No order details available</Typography>
          )}
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Pesan untuk dibagikan</DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(message);
              alert("Pesan berhasil disalin!");
            }}
          >
            Copy Pesan
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OrderPrint;
