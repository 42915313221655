// path: src/features/products/components/ProductList.js

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import theme from "../../../theme";

const ProductList = ({ products, onProductClick }) => {
  const formatRupiah = (value) => {
    if (!value) return "Rp 0";
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: theme.boxShadow.primary }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Gambar</TableCell>
            <TableCell>Nama</TableCell>
            <TableCell>Kategori</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Harga</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow
              key={product.id_produk}
              onClick={() => onProductClick(product)}
            >
              <TableCell>
                <img src={product.foto_produk} alt={product.name} width="50" />
              </TableCell>
              <TableCell>{product.nama}</TableCell>
              <TableCell>{product.kategori}</TableCell>
              <TableCell>{product.nama_vendor}</TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                {formatRupiah(product.harga_jual)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductList;
