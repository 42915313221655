// path: src/features/Sales/PrintReceipt.js
import React, { useEffect, useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import ReceiptThermal from "./Receipt/ReceiptThermal";
import ReceiptA5 from "./Receipt/ReceiptA5";
import { clearOrder } from "./slices/orderSlice";
import { clearCustomerData } from "./slices/customerSlice";
import theme from "../../theme";
import {
  Print,
  Receipt,
  ReceiptLong,
  Send,
  WhatsApp,
} from "@mui/icons-material";
import html2canvas from "html2canvas";

const PrintReceipt = () => {
  const location = useLocation();
  const [transactionData, setTransactionData] = useState(
    location.state?.transactionData || {}
  );
  const [userName, setUserName] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Menggunakan breakpoint 'sm' sebagai batas mobile

  const [printType, setPrintType] = useState(isMobile ? "thermal" : "A5");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userData?.id);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Jika tidak ada data di state, coba ambil dari localStorage
    if (!transactionData || Object.keys(transactionData).length === 0) {
      const data = JSON.parse(localStorage.getItem("transactionData"));
      if (data) {
        setTransactionData(data);
      }
    }
  }, [transactionData]);

  const handleHome = () => {
    localStorage.removeItem("transactionData");
    setTransactionData({});
    dispatch(clearOrder());
    dispatch(clearCustomerData());
    navigate("/");
  };

  const handlePrint = async () => {
    const element = document.getElementById("printArea"); // Pastikan ID ini sesuai dengan elemen yang ingin Anda cetak
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png");

    // Periksa apakah antarmuka Android tersedia
    if (window.Android && window.Android.print) {
      // Panggil fungsi print di antarmuka Android
      window.Android.print(image);
    } else {
      // Cetak melalui jendela baru di browser
      const newWindow = window.open("", "_blank");
      newWindow.document.write(
        `<img src="${image}" onload="window.print();window.close()" />`
      );
      newWindow.focus();
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          "https://api.ajegjogja.com/api/users/user-profile",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUserName(response.data.nama_lengkap);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (userId) {
      fetchUserProfile();
    }
  }, [userId]);

  const handleShare = async () => {
    try {
      const response = await axios.post(
        "https://api.ajegjogja.com/api/transactions/save-order",
        transactionData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const orderId = response.data.orderId;
      const shareUrl = `https://users.ajeg.co.id/nota/${orderId}`;
      const message = `Terimakasih telah berbelanja di Ajeg Furniture, notamu bisa di cek di: ${shareUrl}`;

      setMessage(message);
      handleOpen();
    } catch (error) {
      console.error("Error sharing transaction:", error);
    }
  };

  // Fungsi untuk menyalin pesan ke clipboard
  const copyMessage = () => {
    const messageElement = document.getElementById("share-message");
    if (messageElement) {
      const range = document.createRange();
      range.selectNode(messageElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      alert("Pesan berhasil disalin!");
    }
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleHome}
          >
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Struk Pesanan
          </Typography>
          <Button variant="contained" onClick={handlePrint} sx={{ mr: 2 }}>
            <Print />
            Cetak
          </Button>
          <Button variant="outlined" onClick={handleShare}>
            <WhatsApp /> Kirim
          </Button>
        </Toolbar>
      </AppBar>
      <Box>
        <Box
          sx={{
            mt: 12,
            ml: 2,
          }}
        >
          <Grid container>
            <Grid
              item
              onClick={() => setPrintType("thermal")}
              sx={{
                border: " solid 1px",
                borderRadius: "50px",
                px: 1,
                mr: 2,
              }}
            >
              <IconButton>
                <ReceiptLong />
                <Typography>Thermal</Typography>
              </IconButton>{" "}
            </Grid>
            <Grid
              item
              onClick={() => setPrintType("A5")}
              sx={{
                border: " solid 1px",
                borderRadius: "50px",
                px: 1,
              }}
            >
              <IconButton>
                <Receipt />
                <Typography>A5</Typography>
              </IconButton>{" "}
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ my: 2 }}
        >
          {transactionData && Object.keys(transactionData).length > 0 ? (
            printType === "thermal" ? (
              <ReceiptThermal
                transactionData={transactionData}
                userName={userName}
              />
            ) : (
              <ReceiptA5
                transactionData={transactionData}
                userName={userName}
              />
            )
          ) : (
            <Typography>No transaction data available</Typography>
          )}
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Pesan untuk dibagikan</DialogTitle>
        <DialogContent>
          <Typography>{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(message);
              alert("Pesan berhasil disalin!");
            }}
          >
            Copy Pesan
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PrintReceipt;
