// path: src/utils/formatRupiah.js

import { format } from "date-fns";

const formatRupiah = (value) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(value);
};

export default formatRupiah;
