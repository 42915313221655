// src/actions/userActions.js

// Definisikan konstanta aksi
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER_ERROR = "SET_USER_ERROR";

export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

const apiUrl = process.env.REACT_APP_BASE_URL;

// Membuat fungsi untuk login yang melakukan request ke API dan menangani token
export const loginUser = (userData) => async (dispatch) => {
  try {
    const response = await fetch(`${apiUrl}/api/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    if (response.ok) {
      console.log("Login response data:", data); // Logging data untuk verifikasi
      dispatch({
        type: LOGIN_USER,
        payload: { user: data.user, token: data.token }, // Pastikan data.user memiliki id
      });
      localStorage.setItem("token", data.token); // Simpan token di local storage
    } else {
      dispatch({
        type: SET_USER_ERROR,
        payload: data || "Invalid username or password", // Gunakan respons body sebagai pesan error
      });
    }
  } catch (error) {
    dispatch({
      type: SET_USER_ERROR,
      payload: error.toString(), // Tangkap error yang tidak diantisipasi
    });
  }
};

// Logout user and clear the local storage
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("token"); // Hapus token dari local storage
  // Tambahkan item lain yang perlu dihapus dari local storage jika diperlukan
  localStorage.removeItem("userProfile");
  localStorage.removeItem("otherUserData");

  dispatch({
    type: LOGOUT_USER, // Aksi untuk mengatur ulang state pengguna di Redux store
  });
};

// action creator to verify the token
export const verifyToken = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const response = await fetch(
        "https://api.ajegjogja.com/api/users/verify",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log("Verification response data:", data); // Tambahkan logging
        dispatch({
          type: LOGIN_USER,
          payload: { user: data.user, token: token },
        });
      } else {
        dispatch(logoutUser());
      }
    } catch (error) {
      dispatch(logoutUser());
    }
  } else {
    dispatch(logoutUser());
  }
};

// Aksi untuk memverifikasi PIN
export const verifyPin = (pin) => async (dispatch) => {
  try {
    const response = await fetch(
      "https://api.ajegjogja.com/api/users/verify-pin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ pin }),
      }
    );

    if (response.ok) {
      return "success";
    } else {
      return "error";
    }
  } catch (error) {
    return "error";
  }
};

// Aksi untuk registrasi pengguna
export const registerUser = (userData) => async (dispatch) => {
  try {
    const response = await fetch(
      "https://api.ajegjogja.com/api/users/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      }
    );

    const data = await response.json();
    if (response.ok) {
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: data || "Registration failed", // Gunakan respons body sebagai pesan error
      });
    }
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.toString(), // Tangkap error yang tidak diantisipasi
    });
  }
};
