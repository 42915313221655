import React, { useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Edit, Delete, ArrowBack } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme";
import CreatePromotionModal from "./CreatePromotionModal"; // Import modal
import { fetchPromotions, deletePromotion } from "./promotionSlice";

const PromotionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const promotions = useSelector((state) => state.promotions.promotions);
  const loading = useSelector((state) => state.promotions.loading);
  const error = useSelector((state) => state.promotions.error);
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    dispatch(fetchPromotions());
  }, [dispatch]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deletePromotion(id));
    } catch (error) {
      console.error("Error deleting promotion:", error);
    }
  };

  return (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Daftar Promosi
          </Typography>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            <AddIcon />
            Buat Promo
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nama</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell>Tanggal Mulai</TableCell>
                <TableCell>Tanggal Berakhir</TableCell>
                <TableCell>Catatan</TableCell>
                <TableCell>Tindakan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promotions.map((promotion) => (
                <TableRow key={promotion.id}>
                  <TableCell>{promotion.promotion_name}</TableCell>
                  <TableCell>
                    {promotion.promotion_type === "percentage"
                      ? `${promotion.discount_value}%`
                      : `Rp ${Number(
                          promotion.discount_value
                        ).toLocaleString()}`}
                  </TableCell>
                  <TableCell>
                    {new Date(promotion.start_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(promotion.end_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{promotion.notes}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(promotion.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CreatePromotionModal open={openModal} onClose={handleCloseModal} />
    </Box>
  );
};

export default PromotionList;
