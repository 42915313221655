// src/middleware/authMiddleware.js
import { jwtDecode } from "jwt-decode"; // Perhatikan penulisan ini, gunakan 'jwtDecode' bukan 'jwt_decode'

const authMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  const token = state.auth?.token || localStorage.getItem("token");

  const isLoginAction = action.type === "auth/login";
  const isLogoutAction = action.type === "auth/logout";
  const isOnLoginPage = window.location.pathname === "/login";

  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      // Jika token sudah kedaluwarsa, hapus token dan arahkan ke halaman login
      store.dispatch({ type: "auth/logout" });
      localStorage.removeItem("token");
      window.location.href = "/login";
      return;
    }
  }

  if (!token && !isLoginAction && !isLogoutAction && !isOnLoginPage) {
    window.location.href = "/login";
    return;
  }

  return next(action);
};

export default authMiddleware;
