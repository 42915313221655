// path: src/features/Customers/customerRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import CustomerList from "./CustomerList";

const CustomerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomerList />} />
    </Routes>
  );
};

export default CustomerRoutes;
