import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "./productSlice";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ProductBar from "./components/ProductBar";
import ProductList from "./components/ProductList";
import ProductCard from "./components/ProductCard";
import EditProductModal from "./components/EditProductModal";
import { Add as AddIcon, ArrowBack } from "@mui/icons-material";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";

const ProductPage = () => {
  const dispatch = useDispatch();
  const { products, loading, error } = useSelector((state) => state.products);
  const [view, setView] = useState("list");
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddProduct = () => {
    navigate("/products/add"); // Menggunakan rute absolut
  };

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

  const handleSearchChange = (query) => {
    setSearch(query);
  };

  const handleCategorySelect = (category) => {
    setCategory(category);
  };

  const filteredProducts = products.filter(
    (product) =>
      (category ? product.kategori === category : true) &&
      ((product.nama &&
        product.nama.toLowerCase().includes(search.toLowerCase())) ||
        (product.vendor &&
          product.vendor.toLowerCase().includes(search.toLowerCase())) ||
        (product.kategori &&
          product.kategori.toLowerCase().includes(search.toLowerCase())) ||
        (product.harga_jual && product.harga_jual.toString().includes(search)))
  );

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <ArrowBack />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, fontSize: isMobile ? "1rem" : "1.2rem" }}
          >
            Daftar Produk
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProduct}
            sx={{ fontSize: isMobile ? "0.75rem" : "1rem" }}
          >
            <AddIcon sx={{ mx: 1 }} />
            Tambah Produk
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: 2, mt: 5 }}>
        <ProductBar
          onCategorySelect={handleCategorySelect}
          onSearch={handleSearchChange}
          onViewChange={handleViewChange}
          view={view}
        />
        {view === "list" ? (
          <ProductList
            products={filteredProducts}
            onProductClick={handleProductClick}
          />
        ) : (
          <Grid container spacing={1}>
            {filteredProducts.map((product) => (
              <Grid item key={product.id_produk} xs={12} sm={4} md={3}>
                <ProductCard
                  product={product}
                  onClick={() => handleProductClick(product)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <EditProductModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        product={selectedProduct}
        fetchProducts={() => dispatch(fetchProducts())}
      />
    </Box>
  );
};

export default ProductPage;
