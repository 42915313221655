// path: features/Customers/customerSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

// Thunk untuk mengambil data pelanggan dari API
export const fetchCustomers = createAsyncThunk(
  "customers/fetchCustomers",
  async () => {
    try {
      const response = await axiosInstance.get("/api/customers");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Thunk untuk mengambil data pelanggan berdasarkan ID
export const fetchCustomerById = createAsyncThunk(
  "customers/fetchCustomerById",
  async (customerId) => {
    try {
      const response = await axiosInstance.get(`/api/customers/${customerId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Thunk untuk mengupdate data pelanggan
export const updateCustomer = createAsyncThunk(
  "customers/updateCustomer",
  async (updatedCustomer) => {
    try {
      const response = await axiosInstance.put(
        `/api/customers/${updatedCustomer.id}`,
        updatedCustomer
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addCustomer = createAsyncThunk(
  "customers/addCustomer",
  async (newCustomer) => {
    try {
      const response = await axiosInstance.post("/api/customers", newCustomer);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const customerSlice = createSlice({
  name: "customers",
  initialState: {
    customers: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customers = action.payload;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.customers.push(action.payload);
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        const index = state.customers.findIndex(
          (customer) => customer.id === action.payload.id
        );
        state.customers[index] = action.payload;
      });
  },
});

export const selectCustomers = (state) => state.customers.customers;
export const selectCustomerStatus = (state) => state.customers.status;
export const selectCustomerError = (state) => state.customers.error;

export default customerSlice.reducer;
