// path: src/features/dashboard/SalesSummary.js

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Grid, Paper, useMediaQuery } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { fetchReportingData, fetchSettlementData } from "./reportingSlice";
import { format } from "date-fns";
import { PieChart } from "@mui/x-charts/PieChart";
import CustomDateRangePicker from "./components/CustomDateRangePicker";
import { LineChart } from "@mui/x-charts/LineChart";
import theme from "../../theme";
import SalesDetails from "./SalesDetails"; // Impor SalesDetails
import SettlementDetails from "./SettlementDetails";
import formatRupiah from "../../utils/formatRupiah";

const SalesSummary = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reporting.data);
  const status = useSelector((state) => state.reporting.status);
  const settlementData = useSelector((state) => state.reporting.settlementData); // Tambahkan ini
  const settlementStatus = useSelector(
    (state) => state.reporting.settlementStatus
  );
  const [filteredData, setFilteredData] = useState({ transactions: [] });
  const [filteredSettlementData, setFilteredSettlementData] = useState([]);
  const [previousData, setPreviousData] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(fetchReportingData());
    dispatch(fetchSettlementData());
  }, [dispatch]);

  useEffect(() => {
    if (
      status === "succeeded" &&
      settlementStatus === "succeeded" && // Pastikan data settlement telah dimuat
      selectedDateRange[0].startDate &&
      selectedDateRange[0].endDate
    ) {
      filterDataByDateRange(
        selectedDateRange[0].startDate,
        selectedDateRange[0].endDate
      );
    }
  }, [selectedDateRange, status, settlementStatus, data, settlementData]);

  const filterDataByDateRange = (start, end) => {
    const startDate = format(start, "yyyy-MM-dd");
    const endDate = format(end, "yyyy-MM-dd");

    // Filter transactions
    const filteredTransactions = data.filter((transaction) => {
      const transactionDate = new Date(transaction.tanggal_transaksi);
      if (isNaN(transactionDate)) return false; // Pastikan tanggal valid
      const transactionDateString = format(transactionDate, "yyyy-MM-dd");
      return (
        transactionDateString >= startDate && transactionDateString <= endDate
      );
    });

    // Filter settlementData
    const filteredSettlements = settlementData.filter((settlement) => {
      const settlementDate = new Date(settlement.tanggal_pembayaran);
      if (isNaN(settlementDate)) return false; // Pastikan tanggal valid
      const settlementDateString = format(settlementDate, "yyyy-MM-dd");
      return (
        settlementDateString >= startDate && settlementDateString <= endDate
      );
    });

    const totalSales = filteredTransactions.reduce(
      (sum, t) => sum + parseInt(t.total_harga, 10),
      0
    );
    const totalProductsSold = filteredTransactions.reduce(
      (sum, t) => sum + t.details.reduce((dSum, d) => dSum + d.jumlah, 0),
      0
    );
    const totalRevenue = filteredTransactions.reduce(
      (sum, t) =>
        sum +
        (t.payments
          ? t.payments.reduce(
              (ps, p) => ps + parseInt(p.jumlah_pembayaran, 10),
              0
            )
          : 0),
      0
    );
    const totalTransactions = filteredTransactions.length;

    setFilteredData({
      transactions: filteredTransactions,
      totalSales,
      totalProductsSold,
      totalRevenue,
      totalTransactions,
    });
    setFilteredSettlementData(filteredSettlements);
    console.log(filteredData);
  };

  const getTotalSalesData = (transactions, isHourly) => {
    const metricsMap = {};

    transactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.tanggal_transaksi);
      const key = isHourly
        ? format(transactionDate, "yyyy-MM-dd HH")
        : format(transactionDate, "yyyy-MM-dd");

      if (!metricsMap[key]) {
        metricsMap[key] = {
          time: transactionDate.getTime(), // Save as timestamp
          totalSales: 0,
          totalRevenue: 0,
          totalProductsSold: 0,
          totalTransactions: 0,
        };
      }

      metricsMap[key].totalSales += parseInt(transaction.total_harga, 10);
      metricsMap[key].totalRevenue += transaction.payments
        ? transaction.payments.reduce(
            (sum, payment) => sum + parseInt(payment.jumlah_pembayaran, 10),
            0
          )
        : 0;
      metricsMap[key].totalProductsSold += transaction.details.reduce(
        (sum, detail) => sum + detail.jumlah,
        0
      );
      metricsMap[key].totalTransactions += 1;
    });

    // Sort the metricsData based on time
    const metricsData = Object.values(metricsMap).sort(
      (a, b) => a.time - b.time
    );

    return metricsData;
  };

  const isSingleDay =
    selectedDateRange[0].endDate - selectedDateRange[0].startDate <= 86400000; // 86400000 ms = 1 day

  const totalSalesData = getTotalSalesData(
    filteredData.transactions,
    isSingleDay
  );

  console.log("Data for LineChart (raw):", totalSalesData);

  console.log("Is Single Day:", isSingleDay);

  const calculatePercentageChange = (current, previous) => {
    if (previous === null || previous === undefined) return 0;
    if (previous === 0) return 100; // Handle division by zero when previous value is zero
    return ((current - previous) / previous) * 100;
  };

  useEffect(() => {
    // Menemukan data transaksi sebelumnya berdasarkan rentang tanggal sebelumnya
    const previousTransactions = data.filter((transaction) => {
      const transactionDate = new Date(transaction.tanggal_transaksi);
      const previousEndDate = new Date(selectedDateRange[0].startDate);
      const previousStartDate = new Date(
        previousEndDate.getFullYear(),
        previousEndDate.getMonth(),
        previousEndDate.getDate() - 1
      );
      return (
        transactionDate >= previousStartDate &&
        transactionDate < previousEndDate
      );
    });

    // Perhitungan total penjualan, penerimaan, dsb. untuk transaksi sebelumnya
    const previousTotalSales = previousTransactions.reduce(
      (sum, t) => sum + parseInt(t.total_harga, 10),
      0
    );
    const previousTotalRevenue = previousTransactions.reduce(
      (sum, t) =>
        sum +
        (t.payments
          ? t.payments.reduce(
              (ps, p) => ps + parseInt(p.jumlah_pembayaran, 10),
              0
            )
          : 0),
      0
    );

    // Perhitungan total produk terjual dan total transaksi untuk transaksi sebelumnya
    const previousTotalProductsSold = previousTransactions.reduce(
      (sum, t) => sum + t.details.reduce((dSum, d) => dSum + d.jumlah, 0),
      0
    );
    const previousTotalTransactions = previousTransactions.length;

    // Set state untuk data transaksi sebelumnya
    setPreviousData({
      totalSales: previousTotalSales,
      totalRevenue: previousTotalRevenue,
      totalProductsSold: previousTotalProductsSold,
      totalTransactions: previousTotalTransactions,
    });
  }, [selectedDateRange, data]);

  const getTopProductsData = (transactions) => {
    const productMap = {};

    transactions.forEach((transaction) => {
      transaction.details.forEach((detail) => {
        if (!productMap[detail.id_produk]) {
          productMap[detail.id_produk] = {
            id_produk: detail.id_produk,
            nama_produk: detail.nama_produk,
            jumlah: 0,
          };
        }
        productMap[detail.id_produk].jumlah += detail.jumlah;
      });
    });

    const productList = Object.values(productMap).sort(
      (a, b) => b.jumlah - a.jumlah
    );

    const topProducts = productList.slice(0, 3);
    const otherProducts = productList.slice(3);

    const otherTotal = otherProducts.reduce(
      (sum, product) => sum + product.jumlah,
      0
    );

    const colors = [
      "rgba(0, 109, 90, 1)",
      "rgba(0, 109, 90, 0.8)",
      "rgba(0, 109, 90, 0.6)",
      "rgba(0, 109, 90, 0.4)",
    ];

    const chartData = topProducts.map((product, index) => ({
      id: product.id_produk,
      label: product.nama_produk,
      value: product.jumlah,
      color: colors[index] || `rgba(0, 109, 90, 0.${9 - index})`, // Example colors, customize as needed
    }));

    if (otherTotal > 0) {
      chartData.push({
        id: "others",
        label: "Lainnya",
        value: otherTotal,
        color: "rgba(0, 109, 90, 0.2)", // Example color for 'Lainnya'
      });
    }

    return chartData;
  };

  return (
    <Box sx={{ p: isMobile ? 0.5 : 2 }}>
      <Typography variant="h6" gutterBottom>
        Ringkasan Penjualan
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <CustomDateRangePicker
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} sx={{ height: "100%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                flex: 1,
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 3,
                  height: "100%",
                  backgroundColor: "#D8EFE3",
                  boxShadow: "none", // Menghilangkan bayangan
                }}
              >
                <Typography variant="subtitle2">Total Penjualan</Typography>
                <Typography variant="h6">
                  {formatRupiah(filteredData.totalSales)}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  {previousData && (
                    <>
                      {calculatePercentageChange(
                        filteredData.totalSales,
                        previousData.totalSales
                      ) >= 0 ? (
                        <ArrowDropUpIcon color="success" />
                      ) : (
                        <ArrowDropDownIcon color="error" />
                      )}
                      <Typography
                        variant="subtitle2"
                        color={
                          calculatePercentageChange(
                            filteredData.totalSales,
                            previousData.totalSales
                          ) >= 0
                            ? "success.main"
                            : "error.main"
                        }
                      >
                        {Math.abs(
                          calculatePercentageChange(
                            filteredData.totalSales,
                            previousData.totalSales
                          )
                        ).toFixed(2)}
                        %
                      </Typography>
                    </>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} sx={{ flex: 1 }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 3,
                  height: "100%",
                  backgroundColor: "#DFF9FB",
                  boxShadow: "none", // Menghilangkan bayangan
                }}
              >
                <Typography variant="subtitle2">Produk Terjual</Typography>
                <Typography variant="h6">
                  {filteredData.totalProductsSold}
                </Typography>
                {previousData && (
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    {calculatePercentageChange(
                      filteredData.totalProductsSold,
                      previousData.totalProductsSold
                    ) >= 0 ? (
                      <ArrowDropUpIcon color="success" />
                    ) : (
                      <ArrowDropDownIcon color="error" />
                    )}
                    <Typography
                      variant="subtitle2"
                      color={
                        calculatePercentageChange(
                          filteredData.totalProductsSold,
                          previousData.totalProductsSold
                        ) >= 0
                          ? "success.main"
                          : "error.main"
                      }
                    >
                      {Math.abs(
                        calculatePercentageChange(
                          filteredData.totalProductsSold,
                          previousData.totalProductsSold
                        )
                      ).toFixed(2)}
                      %
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} sx={{ flex: 1 }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 3,
                  height: "100%",
                  backgroundColor: "#FFF2D9",
                  boxShadow: "none", // Menghilangkan bayangan
                }}
              >
                <Typography variant="subtitle2">Penerimaan</Typography>
                <Typography variant="h6">
                  {formatRupiah(filteredData.totalRevenue)}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  {previousData && (
                    <>
                      {calculatePercentageChange(
                        filteredData.totalRevenue,
                        previousData.totalRevenue
                      ) >= 0 ? (
                        <ArrowDropUpIcon color="success" />
                      ) : (
                        <ArrowDropDownIcon color="error" />
                      )}
                      <Typography
                        variant="subtitle2"
                        color={
                          calculatePercentageChange(
                            filteredData.totalRevenue,
                            previousData.totalRevenue
                          ) >= 0
                            ? "success.main"
                            : "error.main"
                        }
                      >
                        {Math.abs(
                          calculatePercentageChange(
                            filteredData.totalRevenue,
                            previousData.totalRevenue
                          )
                        ).toFixed(2)}
                        %
                      </Typography>
                    </>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} sx={{ flex: 1 }}>
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 3,
                  height: "100%",
                  backgroundColor: "#FFEBE3",
                  boxShadow: "none", // Menghilangkan bayangan
                }}
              >
                <Typography variant="subtitle2">Jumlah Transaksi</Typography>
                <Typography variant="h6">
                  {filteredData.totalTransactions}
                </Typography>
                {previousData && (
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    {calculatePercentageChange(
                      filteredData.totalTransactions,
                      previousData.totalTransactions
                    ) >= 0 ? (
                      <ArrowDropUpIcon color="success" />
                    ) : (
                      <ArrowDropDownIcon color="error" />
                    )}
                    <Typography
                      variant="subtitle2"
                      color={
                        calculatePercentageChange(
                          filteredData.totalTransactions,
                          previousData.totalTransactions
                        ) >= 0
                          ? "success.main"
                          : "error.main"
                      }
                    >
                      {Math.abs(
                        calculatePercentageChange(
                          filteredData.totalTransactions,
                          previousData.totalTransactions
                        )
                      ).toFixed(2)}
                      %
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper
            sx={{
              p: 2,
              flexGrow: 1,
              borderRadius: 3,
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              boxShadow: theme.boxShadow.primary,
            }}
          >
            <Typography variant="subtitle2">List Produk Terjual</Typography>
            {filteredData.transactions.length > 0 && (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    overflow: "hidden",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <PieChart
                    height={100}
                    width={200}
                    series={[
                      {
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                        data: getTopProductsData(filteredData.transactions),
                      },
                    ]}
                    slotProps={{
                      legend: { hidden: true },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    {getTopProductsData(filteredData.transactions).map(
                      (entry, index) => (
                        <Box
                          key={index}
                          sx={{ display: "flex", alignItems: "center", mt: 1 }}
                        >
                          <Box
                            sx={{
                              width: 16,
                              height: 16,
                              backgroundColor: entry.color,
                              marginRight: 1,
                            }}
                          />
                          <Typography variant="body2">{entry.label}</Typography>
                        </Box>
                      )
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Box
        mt={4}
        sx={{ boxShadow: theme.boxShadow.primary, p: 2, borderRadius: 3 }}
      >
        <Typography variant="h6" gutterBottom>
          Grafik Penjualan Harian
        </Typography>
        {filteredData.transactions.length > 0 && (
          <Box
            sx={{
              height: isMobile ? "250px" : "400px",
              width: isMobile ? "300px" : "1200px",
            }}
          >
            <LineChart
              xAxis={[
                {
                  dataKey: "time",
                  valueFormatter: (value) => {
                    const date = new Date(value); // No conversion to WIB
                    const formattedValue = isSingleDay
                      ? format(date, "HH:mm") // Format for hourly data
                      : format(date, "yyyy-MM-dd"); // Format for daily data
                    return formattedValue;
                  },
                },
              ]}
              yAxis={[
                {
                  id: "leftAxisId",
                  valueFormatter: (value) =>
                    (value / 1000).toLocaleString("id-ID"), // Format in thousands
                },
                {
                  id: "rightAxisId",
                  valueFormatter: (value) => value.toLocaleString("id-ID"), // Format for count
                },
              ]}
              rightAxis="rightAxisId"
              series={[
                {
                  yAxisKey: "leftAxisId", // Use left Y-Axis for this series
                  dataKey: "totalRevenue",
                  label: "Penerimaan",
                  color: "rgb(0, 167, 111)", // Customize the color as needed
                },
                {
                  yAxisKey: "leftAxisId", // Use left Y-Axis for this series
                  dataKey: "totalSales",
                  label: "Total Penjualan",
                  color: "rgba(0, 109, 90, 1)", // Customize the color as needed
                },

                {
                  yAxisKey: "rightAxisId", // Use right Y-Axis for this series
                  dataKey: "totalProductsSold",
                  label: "Produk Terjual",
                  color: "rgb(0, 184, 217)", // Customize the color as needed
                },
                {
                  yAxisKey: "rightAxisId", // Use right Y-Axis for this series
                  dataKey: "totalTransactions",
                  label: "Transaksi",
                  color: "rgba(255, 165, 0, 1)", // Customize the color as needed
                },
              ]}
              dataset={totalSalesData}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        mt={4}
        sx={{ boxShadow: theme.boxShadow.primary, p: 2, borderRadius: 3 }}
      >
        <SalesDetails transactions={filteredData.transactions} />
        <SettlementDetails settlementData={filteredSettlementData} />
      </Box>
    </Box>
  );
};

export default SalesSummary;
