// path: src/features/dashboard/SalesDetails.js
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Typography,
  useMediaQuery,
  Tab,
} from "@mui/material";
import formatRupiah from "../../utils/formatRupiah"; // Pastikan formatRupiah tersedia di utils atau di file ini

const SalesDetails = ({ transactions }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Mengumpulkan data sales
  const salesData = transactions.reduce((acc, transaction) => {
    const salesName = transaction.sales_name;
    if (!acc[salesName]) {
      acc[salesName] = {};
    }

    transaction.details.forEach((detail) => {
      const productName = detail.nama_produk;
      if (!acc[salesName][productName]) {
        acc[salesName][productName] = {
          jumlah: 0,
          totalPenjualan: 0,
          totalPenerimaan: 0,
          ongkir: transaction.ongkir || 0, // Tambahkan ongkir dari transaksi
        };
      }

      acc[salesName][productName].jumlah += detail.jumlah;
      acc[salesName][productName].totalPenjualan +=
        parseInt(detail.harga_satuan, 10) * detail.jumlah;
      acc[salesName][productName].totalPenerimaan += transaction.payments
        ? transaction.payments.reduce(
            (sum, payment) => sum + parseInt(payment.jumlah_pembayaran, 10),
            0
          )
        : 0;
    });

    return acc;
  }, {});

  // Mengubah data ke dalam bentuk array
  const salesDetailsArray = [];
  Object.keys(salesData).forEach((salesName) => {
    Object.keys(salesData[salesName]).forEach((productName) => {
      salesDetailsArray.push({
        salesName,
        productName,
        ...salesData[salesName][productName],
      });
    });
  });

  // Pagination handling
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      sx={{
        width: isMobile ? "300px" : "1200px",
        overflowX: "auto",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Detail Penjualan
      </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Nama Sales</TableCell>
              <TableCell>Nama Produk</TableCell>
              <TableCell>Jumlah</TableCell>
              <TableCell>Total Penjualan</TableCell>
              <TableCell>Ongkir</TableCell>
              <TableCell>Total Penerimaan</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesDetailsArray
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.salesName}</TableCell>
                  <TableCell>{row.productName}</TableCell>
                  <TableCell>{row.jumlah}</TableCell>
                  <TableCell>{formatRupiah(row.totalPenjualan)}</TableCell>
                  <TableCell>{formatRupiah(row.ongkir)}</TableCell>
                  <TableCell>{formatRupiah(row.totalPenerimaan)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={salesDetailsArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default SalesDetails;
