import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateProduct } from "../productSlice";

const EditProductModal = ({ open, handleClose, product, fetchProducts }) => {
  const [formValues, setFormValues] = useState({
    estimasi_waktu_produksi: "",
    panjang: "",
    lebar: "",
    tinggi: "",
    deskripsi: "",
    harga_jual: "",
    harga_beli: "",
  });

  const [isEditable, setIsEditable] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (product) {
      setFormValues({
        estimasi_waktu_produksi: product.estimasi_waktu_produksi || "",
        panjang: product.panjang || "",
        lebar: product.lebar || "",
        tinggi: product.tinggi || "",
        deskripsi: product.deskripsi || "",
        harga_jual: product.harga_jual || "",
        harga_beli: product.harga_beli || "",
      });
    }
  }, [product]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    let tempErrors = {};
    for (const [key, value] of Object.entries(formValues)) {
      if (!value) {
        tempErrors[key] = "This field is required";
      }
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      console.log("Mengirim data ke server:", formValues);
      await dispatch(
        updateProduct({ id: product.id_produk, formData: formValues })
      );
      console.log("Berhasil memperbarui produk");
      handleClose();
      setSnackbar({
        open: true,
        message: "Product updated successfully",
        severity: "success",
      });
      console.log("Menampilkan Snackbar");
      setTimeout(() => {
        console.log("Memanggil fetchProducts");
        fetchProducts();
      }, 1000); // Delay 1 detik sebelum memanggil fetchProducts
    } catch (error) {
      console.error("Error updating product:", error);
      setSnackbar({
        open: true,
        message: "Failed to update product",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleCancelClick = () => {
    setIsEditable(false);
    setFormValues({
      estimasi_waktu_produksi: product.estimasi_waktu_produksi || "",
      panjang: product.panjang || "",
      lebar: product.lebar || "",
      tinggi: product.tinggi || "",
      deskripsi: product.deskripsi || "",
      harga_jual: product.harga_jual || "",
      harga_beli: product.harga_beli || "",
    });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : 400,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Product : {product?.nama}
          </Typography>

          <Box mt={2} />
          <form onSubmit={handleSubmit}>
            {isEditable ? (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      label="Estimasi Waktu Produksi"
                      name="estimasi_waktu_produksi"
                      value={formValues.estimasi_waktu_produksi}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.estimasi_waktu_produksi}
                      helperText={errors.estimasi_waktu_produksi}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Panjang"
                      name="panjang"
                      value={formValues.panjang}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.panjang}
                      helperText={errors.panjang}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Lebar"
                      name="lebar"
                      value={formValues.lebar}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.lebar}
                      helperText={errors.lebar}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Tinggi"
                      name="tinggi"
                      value={formValues.tinggi}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.tinggi}
                      helperText={errors.tinggi}
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="Deskripsi"
                  name="deskripsi"
                  value={formValues.deskripsi}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  error={!!errors.deskripsi}
                  helperText={errors.deskripsi}
                />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label="Harga Jual"
                      name="harga_jual"
                      value={formValues.harga_jual}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.harga_jual}
                      helperText={errors.harga_jual}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {`Rp. ${Number(formValues.harga_jual).toLocaleString(
                        "id-ID"
                      )}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Harga Beli"
                      name="harga_beli"
                      value={formValues.harga_beli}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.harga_beli}
                      helperText={errors.harga_beli}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {`Rp. ${Number(formValues.harga_beli).toLocaleString(
                        "id-ID"
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      label="Estimasi Waktu Produksi"
                      name="estimasi_waktu_produksi"
                      value={formValues.estimasi_waktu_produksi}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Panjang"
                      name="panjang"
                      value={formValues.panjang}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Lebar"
                      name="lebar"
                      value={formValues.lebar}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Tinggi"
                      name="tinggi"
                      value={formValues.tinggi}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  label="Deskripsi"
                  name="deskripsi"
                  value={formValues.deskripsi}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label="Harga Jual"
                      name="harga_jual"
                      value={formValues.harga_jual}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {`Rp. ${Number(formValues.harga_jual).toLocaleString(
                        "id-ID"
                      )}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Harga Beli"
                      name="harga_beli"
                      value={formValues.harga_beli}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {`Rp. ${Number(formValues.harga_beli).toLocaleString(
                        "id-ID"
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              {!isEditable ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancelClick}
                  >
                    Batal
                  </Button>
                  <Button type="submit" variant="contained" color="primary">
                    Simpan
                  </Button>
                </>
              )}
            </Box>
          </form>
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProductModal;
