// path: src/features/dashboard/Dashboard.js
import React from "react";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import DashboardAppbar from "./components/DashboardAppBar";
import DashboardRoutes from "./dashboardRoutes";

const Dashboard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "white",
        backgroundSize: "cover",
        minHeight: "100vh",
      }}
    >
      <CssBaseline />
      <DashboardAppbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        <Box sx={{ display: "flex" }}>
          <DashboardRoutes /> {/* Render the routes component */}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
