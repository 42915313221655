import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
    h1: {
      fontWeight: 700,
      fontSize: "3.0rem",
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.6rem",
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.2rem",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.8rem",
    },
    h5: {
      fontWeight: 700,
      fontSize: "1.2rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.25rem",
    },
    subtitle1: {
      fontWeight: 600, // Normal subtitle
      fontSize: "1.0rem",
    },
    body1: {
      fontWeight: 500, // Normal body text
      fontSize: "1.0rem",
    },
    body2: {
      fontWeight: 400, // Sedikit lebih kecil dari body1
      fontSize: "0.875rem",
    },
    button: {
      fontWeight: 600, // Berat font untuk button
      fontSize: "0.875rem",
    },
  },

  // Anda dapat menambahkan lebih banyak penyesuaian untuk elemen-elemen lain seperti subtitle, caption, dll.

  palette: {
    primary: {
      main: "#25574A",
      dark: "#1A3F3A",
    },
    secondary: {
      main: "#975A16",
    },
    merah: {
      main: "#f20202",
    },
    third: {
      main: "#D69E2E", // Tambahkan warna ketiga di sini
    },
    yellow: {
      main: "#ECC94B", // Tambahkan warna
    },
  },

  boxShadow: {
    primary:
      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px", // Tambahkan boxShadow untuk warna primer
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Menambahkan borderRadius untuk membuat tombol lebih bulat
          textTransform: "none", // Tambahkan styling lain yang Anda inginkan untuk tombol di sini
        },
      },
    },
    // Customisasi komponen lain jika diperlukan
  },
});

export default theme;
