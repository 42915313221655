import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addPromotion } from "./promotionSlice";

const CreatePromotionModal = ({ open, onClose }) => {
  const [promotionName, setPromotionName] = useState("");
  const [promotionType, setPromotionType] = useState("percentage");
  const [discountValue, setDiscountValue] = useState("");
  const [minimumPurchase, setMinimumPurchase] = useState("");
  const [maximumDiscount, setMaximumDiscount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [notes, setNotes] = useState("");
  const [banner, setBanner] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const promotionData = {
      promotionName,
      promotionType,
      discountValue,
      minimumPurchase,
      startDate,
      endDate,
      notes,
      banner,
    };

    if (promotionType === "percentage") {
      promotionData.maximumDiscount = maximumDiscount;
    }

    try {
      await dispatch(addPromotion(promotionData));

      onClose();
      // Reset form fields
      setPromotionName("");
      setPromotionType("percentage");
      setDiscountValue("");
      setMinimumPurchase("");
      setMaximumDiscount("");
      setStartDate("");
      setEndDate("");
      setNotes("");
      setBanner(null);
      setBannerPreview(null);
    } catch (error) {
      console.error("Error creating promotion:", error);
    }
  };

  const handleBannerChange = (event) => {
    const file = event.target.files[0];
    setBanner(file);
    setBannerPreview(URL.createObjectURL(file));
  };

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "90%" : 400,
          height: isMobile ? "90%" : "auto",
          p: 4,
          backgroundColor: "white",
          margin: "auto",
          borderRadius: 2,
          boxShadow: 24,
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Buat Promo & Diskon Baru
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Nama Promo"
                value={promotionName}
                onChange={(e) => setPromotionName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Jenis Promo</FormLabel>
                <RadioGroup
                  aria-label="promotionType"
                  name="promotionType"
                  value={promotionType}
                  onChange={(e) => setPromotionType(e.target.value)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="percentage"
                        control={<Radio />}
                        label="Persentase"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        value="nominal"
                        control={<Radio />}
                        label="Nominal"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                label={
                  promotionType === "percentage"
                    ? "Persentase Diskon (%)"
                    : "Nominal Diskon"
                }
                type="number"
                value={discountValue}
                onChange={(e) => setDiscountValue(e.target.value)}
                inputProps={{ min: 0 }}
              />
            </Grid>
            {promotionType === "percentage" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  label="Maksimal Potongan"
                  type="number"
                  value={maximumDiscount}
                  onChange={(e) => setMaximumDiscount(e.target.value)}
                  inputProps={{ min: 0 }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Minimal Pembelian"
                type="number"
                value={minimumPurchase}
                onChange={(e) => setMinimumPurchase(e.target.value)}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Tanggal Mulai"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    label="Tanggal Berakhir"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Catatan"
                multiline
                rows={3}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", mt: 2 }}
          >
            <Grid item xs={6}>
              <Button onClick={onClose} color="secondary">
                Batal
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SendIcon />}
              >
                Buat Promosi
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreatePromotionModal;
