// path: src/features/dashboard/components/DashboardBar.js
import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const DashboardAppbar = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
        color: theme.palette.primary.main,
        borderBottom: "1px solid #E4E4E4",
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          onClick={handleBack}
          sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ fontSize: isMobile ? "1rem" : "1.2rem" }}
        >
          Sales Dashboard
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardAppbar;
