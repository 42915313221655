import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  Divider,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Autocomplete,
} from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import { updateCustomer } from "../customerSlice";

const CustomerDetailModal = ({ open, handleClose, customer }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [customerData, setCustomerData] = useState({
    id: "",
    title: "",
    nama_pelanggan: "",
    nomor_hp: "",
    provinsi: "",
    kabupaten: "",
    kecamatan: "",
    kelurahan: "",
    alamat_pelanggan: "",
  });

  const [provinsiOptions, setProvinsiOptions] = useState([]);
  const [kotaOptions, setKotaOptions] = useState([]);
  const [kecamatanOptions, setKecamatanOptions] = useState([]);
  const [kelurahanOptions, setKelurahanOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (customer) {
      setCustomerData({
        id: customer.id || "",
        title: customer.title || "",
        nama_pelanggan: customer.nama_pelanggan || "",
        nomor_hp: customer.nomor_hp || "",
        provinsi: customer.provinsi || "",
        kabupaten: customer.kabupaten || "",
        kecamatan: customer.kecamatan || "",
        kelurahan: customer.kelurahan || "",
        alamat_pelanggan: customer.alamat_pelanggan || "",
      });
    }
  }, [customer]);

  const handleEditClick = () => {
    setIsEditable(true);
    fetchProvinsi(); // Fetch provinsi saat mode edit diaktifkan
  };

  const fetchProvinsi = async () => {
    try {
      const response = await axios.get(
        "https://api.ajegjogja.com/api/alamat/provinsi"
      );
      setProvinsiOptions(response.data);
    } catch (error) {
      console.error("Error fetching provinsi:", error);
    }
  };

  const fetchKota = async (provinsiId) => {
    try {
      const response = await axios.get(
        `https://api.ajegjogja.com/api/alamat/kota`,
        {
          params: { provinsi_id: provinsiId },
        }
      );
      setKotaOptions(response.data);
    } catch (error) {
      console.error("Error fetching kota:", error);
    }
  };

  const fetchKecamatan = async (kotaId) => {
    try {
      const response = await axios.get(
        `https://api.ajegjogja.com/api/alamat/kecamatan`,
        {
          params: { kota_id: kotaId },
        }
      );
      setKecamatanOptions(response.data);
    } catch (error) {
      console.error("Error fetching kecamatan:", error);
    }
  };

  const fetchKelurahan = async (kecamatanId) => {
    try {
      const response = await axios.get(
        `https://api.ajegjogja.com/api/alamat/kelurahan`,
        {
          params: { kecamatan_id: kecamatanId },
        }
      );
      setKelurahanOptions(response.data);
    } catch (error) {
      console.error("Error fetching kelurahan:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevCustomer) => ({ ...prevCustomer, [name]: value }));
  };

  const handleProvinsiChange = (event, value) => {
    setCustomerData((prevCustomer) => ({
      ...prevCustomer,
      provinsi: value ? value.name : "",
      kabupaten: "",
      kecamatan: "",
      kelurahan: "",
    }));
    if (value) fetchKota(value.id);
  };

  const handleKotaChange = (event, value) => {
    setCustomerData((prevCustomer) => ({
      ...prevCustomer,
      kabupaten: value ? value.name : "",
      kecamatan: "",
      kelurahan: "",
    }));
    if (value) fetchKecamatan(value.id);
  };

  const handleKecamatanChange = (event, value) => {
    setCustomerData((prevCustomer) => ({
      ...prevCustomer,
      kecamatan: value ? value.name : "",
      kelurahan: "",
    }));
    if (value) fetchKelurahan(value.id);
  };

  const handleKelurahanChange = (event, value) => {
    setCustomerData((prevCustomer) => ({
      ...prevCustomer,
      kelurahan: value ? value.name : "",
    }));
  };

  const handleSave = () => {
    dispatch(updateCustomer(customerData));
    setIsEditable(false);
    handleClose();
  };

  if (!customer) {
    return null; // Mengembalikan null jika tidak ada data customer
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          Detail Pelanggan
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Sapaan</InputLabel>
              <Select
                value={customerData.title || ""}
                size="small"
                onChange={handleInputChange}
                label="Sapaan"
                name="title"
                disabled={!isEditable}
              >
                <MenuItem value="Bapak">Bapak</MenuItem>
                <MenuItem value="Ibu">Ibu</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nama Lengkap"
              name="nama_pelanggan"
              size="small"
              value={customerData.nama_pelanggan}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nomor HP"
              size="small"
              name="nomor_hp"
              type="tel"
              value={customerData.nomor_hp}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </Grid>
          {isEditable ? (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  options={provinsiOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={handleProvinsiChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Provinsi"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={kotaOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={handleKotaChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kota"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={kecamatanOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={handleKecamatanChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kecamatan"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={kelurahanOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={handleKelurahanChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kelurahan"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Provinsi"
                  name="provinsi"
                  size="small"
                  value={customerData.provinsi}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Kota"
                  name="kabupaten"
                  size="small"
                  value={customerData.kabupaten}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Kecamatan"
                  name="kecamatan"
                  size="small"
                  value={customerData.kecamatan}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Kelurahan"
                  name="kelurahan"
                  size="small"
                  value={customerData.kelurahan}
                  onChange={handleInputChange}
                  disabled={!isEditable}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Alamat Lengkap"
              size="small"
              name="alamat_pelanggan"
              multiline
              rows={3}
              value={customerData.alamat_pelanggan}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          {!isEditable ? (
            <Button
              onClick={handleEditClick}
              color="primary"
              variant="contained"
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                onClick={() => setIsEditable(false)}
                color="secondary"
                sx={{ mr: 2 }}
              >
                Batal
              </Button>
              <Button onClick={handleSave} color="primary" variant="contained">
                Simpan
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomerDetailModal;
