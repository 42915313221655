// features/Sales/ProductsList.js
import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import { Grid } from "@mui/material";
import { fetchProducts } from "../products/productSlice";
import { useDispatch, useSelector } from "react-redux";

const ProductsList = ({ category, searchQuery, onProductClick }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    // Menyaring produk berdasarkan kategori yang dipilih
    let filtered = category
      ? products.filter((product) => product.kategori === category)
      : products;

    // Menyaring produk berdasarkan pencarian
    if (searchQuery) {
      filtered = filtered.filter((product) =>
        product.nama.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredProducts(filtered);
  }, [category, searchQuery, products]);

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {filteredProducts.map((product) => (
        <Grid item key={product.id_produk} xs={12} sm={6} md={4} lg={3}>
          <ProductCard
            product={product}
            onClick={() => onProductClick(product)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductsList;
