// features/Sales/ProductCard.js
import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import theme from "../../theme";

const ProductCard = ({ product, onClick }) => {
  const formatRupiah = (value) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "solid 1px",
        borderColor: "#E4E4E4",
        borderRadius: 4,
        padding: 1,
        boxShadow: "none", // Menghapus shadow dari card
      }}
    >
      <CardMedia
        component="img"
        height="140"
        image={product.foto_produk}
        alt={product.nama}
        sx={{ borderRadius: 2 }}
      />
      <CardContent>
        <Typography gutterBottom variant="body2" component="div">
          {product.nama}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.primary.main, fontWeight: "600" }}
        >
          {formatRupiah(product.harga_jual)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
