// path: features/Customers/CustomerList.js
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchCustomers,
  selectCustomers,
  selectCustomerStatus,
  selectCustomerError,
} from "./customerSlice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Alert,
  TablePagination,
  Box,
  AppBar,
  Toolbar,
  useMediaQuery,
  IconButton,
  Tab,
  Button,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SearchIcon from "@mui/icons-material/Search";
import theme from "../../theme";
import { Add } from "@mui/icons-material";
import CustomerModal from "./components/CustomerModal";
import CustomerDetailModal from "./components/CustomerDetailModal";

const CustomerList = () => {
  const dispatch = useDispatch();
  const customers = useSelector(selectCustomers);
  const customerStatus = useSelector(selectCustomerStatus);
  const customerError = useSelector(selectCustomerError);

  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");

  const [open, setOpen] = useState(false); // State untuk modal

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [detailOpen, setDetailOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setDetailOpen(true);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  useEffect(() => {
    if (customerStatus === "idle") {
      dispatch(fetchCustomers());
    }
  }, [customerStatus, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleBack = () => {
    navigate(-1);
  };

  // Fungsi untuk menangani perubahan input pencarian
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.nama_pelanggan.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (customerStatus === "loading") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (customerStatus === "failed") {
    return <Alert severity="error">{customerError}</Alert>;
  }

  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          color: theme.palette.primary.main,
          borderBottom: "1px solid #E4E4E4",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleBack}
            sx={{ marginRight: 2, borderRadius: "50%", border: "1px solid" }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, fontSize: isMobile ? "1rem" : "1.2rem" }}
          >
            Daftar Pelanggan
          </Typography>
          <Button variant="contained" onClick={handleOpen}>
            <Add />
            Tambah
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          m: 2,
          mt: 10,
          p: 1,
          borderRadius: 3,
          boxShadow: theme.boxShadow.primary,
        }}
      >
        <TextField
          fullWidth
          size="small"
          placeholder="Cari Nama Pelanggan"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { borderRadius: "8px" }, // Adjust the radius as needed
          }}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sapaan</TableCell>
                <TableCell>Nama</TableCell>
                <TableCell>Kota</TableCell>
                <TableCell>Nomor HP</TableCell>
                <TableCell>Jumlah Transaksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customer) => (
                  <TableRow
                    key={customer.id}
                    onClick={() => handleRowClick(customer)}
                  >
                    <TableCell>{customer.title}</TableCell>
                    <TableCell>{customer.nama_pelanggan}</TableCell>
                    <TableCell>{customer.kabupaten}</TableCell>
                    <TableCell>{customer.nomor_hp}</TableCell>
                    <TableCell>{customer.jumlah_transaksi}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
      <CustomerModal open={open} handleClose={handleClose} />
      <CustomerDetailModal
        open={detailOpen}
        handleClose={handleDetailClose}
        customer={selectedCustomer}
      />
    </Box>
  );
};

export default CustomerList;
