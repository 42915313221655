// path: src/features/Sales/SalesRoutes/index.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import SalesPage from "../SalesPage";
import OrderConfirmationPage from "../OrderConfirmation/OrderConfirmationPage";
import PrintReceipt from "../PrintReceipt";

const SalesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SalesPage />} />
      <Route path="order-confirmation" element={<OrderConfirmationPage />} />
      <Route path="print-receipt" element={<PrintReceipt />} />
    </Routes>
  );
};

export default SalesRoutes;
